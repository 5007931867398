import React, { useEffect, useReducer, useRef, useState } from 'react';
import NavBar from "../../components/NavBar";

import {LayersControl, MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import "./LightsMap.css"
import 'react-leaflet-markercluster/styles'
import {IoArrowForward, IoFlash, IoSearchOutline} from "react-icons/io5";


import { LocateControl } from "leaflet.locatecontrol";
import 'leaflet.fullscreen';
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import 'leaflet.fullscreen/Control.FullScreen.css';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import {GiStreetLight} from "react-icons/gi";
import MarkerClusterGroup from "react-leaflet-markercluster";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingError from "../../components/LoadingError";



// Make sure to use your actual Mapbox access token

const Map = () => {

    const searchIfEnter = (e) => {
        e.key === "Enter" && refreshSearch()
    }

    const [map, setMap] = useState()

    const [isLoading, setIsLoading] = useState(true)
    const [loadingError, setLoadingError] = useState(false)
    const [loadingErrorDescription, setLoadingErrorDescription] = useState()


    const search = useRef();
    const searchCity = useRef();
    const [results, setResults] = useState([])
    const [pointAddress, setPointAddress] = useState("Caricamento in corso...")


    const [state, dispatch] = useReducer(
        (prevState, action)  => {
            switch (action.type) {
                case 'setMapPoints':
                    return {
                        switchBoards: action.switchBoards,
                        streetLights: action.streetLights,
                    };

                default:
                    return prevState;
            }
        },
        {
            streetLights: [],
            switchBoards: [],
        }
    );



    const refreshSearch = () => {

        if (search.current?.value === "") {
            setResults([])
            return
        }
        //Search both switchboard name and streetlight name, limit 5 results
        setResults([
            ...state.switchBoards
                .filter((value) => value.name.toLowerCase().includes(search.current?.value.toLowerCase()) && value.city.toLowerCase().includes(searchCity.current?.value.toLowerCase()))
                .slice(0, 10)
                .map((value) => ({ ...value, type: 'switchboard' })),
            ...state.streetLights
                .filter((value) => value.name.toLowerCase().includes(search.current?.value.toLowerCase()) && value.city.toLowerCase().includes(searchCity.current?.value.toLowerCase()))
                .slice(0, 5)
                .map((value) => ({ ...value, type: 'streetlight' }))
        ]);

    };

    const streetLightDataRetriever = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/streetlights', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });


            const responseJson = await response.json();

            if (response.status !== 200) {
                setLoadingErrorDescription(responseJson.message)
                setLoadingError(true)
            }


            dispatch({
                type: "setMapPoints",
                switchBoards: responseJson.switchboards,
                streetLights: responseJson.streetlights
            })

        } catch (error) {
            console.log(error)
            setLoadingError(true)

        }
    }

    const getAddressFromCoordinates = async (lat, lng) => {
        /*
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`);
            const data = await response.json();
            console.log("qua ci siamo")
            if (data && data.display_name) {
                setPointAddress(data.display_name);
            } else {
                setPointAddress("Indirizzo non trovato");
            }
        } catch (error) {
            setPointAddress("Indirizzo non trovato");
        }

         */
    };

    useEffect(() => {
        streetLightDataRetriever().then(() => setIsLoading(false))
    }, []);

    useEffect(() => {
        if (map) {

            //Add full screen button
            L.control.fullscreen({
                position: 'topleft',
                title: {
                    'false': 'Visualizza a schermo intero',
                    'true': 'Esci da schermo intero'
                }
            }).addTo(map);

            //Add Localization
            new LocateControl().addTo(map);

        }
    }, [map]);

    const createFlashMapIcon = (name) => L.divIcon({
        html: ReactDOMServer.renderToString(
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ backgroundColor: 'black', borderRadius: '20%', height: '20px', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IoFlash style={{ color: 'yellow', fontSize: '12px' }} />
                </div>
                <div style={{ color: 'black', fontSize: '12px' }}>{name}</div>
            </div>
        ),
        className: 'custom-icon',
        iconSize: [24, 24],
        iconAnchor: [12, 24],
        popupAnchor: [0, -24]
    });

    const createLightMapIcon = (name) => L.divIcon({
        html: ReactDOMServer.renderToString(
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <GiStreetLight style={{ color: 'black', fontSize: '24px' }} />
                <div style={{ color: 'black', fontSize: '12px' }}>
                    {name}
                </div>
            </div>
        ),
        className: 'custom-icon',
        iconSize: [24, 24],
        iconAnchor: [12, 24],
        popupAnchor: [0, -24]
    });


    const openInGoogleMapsNavigation = (coordinates) => {
        const [lng, lat] = coordinates;
        const destination = encodeURIComponent(`${lat},${lng}`); // Use name or coordinates
        const googleMapsUrl = `https://www.google.com/maps/dir//${destination}`; // Navigation URL

        window.open(googleMapsUrl, '_blank');

    };

    const centerOnSwitchboard = (streetLight) => {
        if (map) {
            const switchBoard = state.switchBoards.find((value) => value.id === streetLight.switchboardId)

            if (!switchBoard) {
                alert("Quadro non trovato")
                return
            }

            map.flyTo([switchBoard.lat, switchBoard.lng], 20, {animate: true, duration: 1})

            //Open the popup on that switchboard
            setTimeout(() => {

                map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.getLatLng().lat === switchBoard.lat && layer.getLatLng().lng === switchBoard.lng) {
                            layer.openPopup();
                        }
                    }
                });
            }, 1500)

        }

    };


    return (
        loadingError ? <LoadingError errorDescription={loadingErrorDescription}/> :
        (isLoading) ? <LoadingSpinner /> :
        <div className="mainContainer lightsMap">
            <NavBar/>
            <h1>Mappa Illuminazione Pubblica</h1>
            <div className="searchBoxContainer">
                <div className="searchBoxRow">
                    <div className="elementFilter">
                        <select name="city" id="city"
                            ref={searchCity}
                        >
                            {state.switchBoards.reduce((acc, value) => {
                                if (!acc.includes(value.city)) {
                                    acc.push(value.city)
                                }
                                return acc
                            }
                            , []).map((city, index) => {
                                return <option key={index} value={city}>{city}</option>
                            })
                            }
                        </select>
                    </div>
                    <div className="elementFilter">
                        <input
                            type="text"
                            placeholder="Cerca"
                            ref={search}
                            onKeyDown={searchIfEnter}
                        />
                    </div>
                    <div className="elementFilter">
                        <button className="searchButton" onClick={() => {refreshSearch()}}><IoSearchOutline /></button>
                    </div>
                </div>

                <table style={{ borderRadius: '30px', overflow: 'hidden' }}>
                <tbody>
                {results.map((result, index) => (
                    <tr key={"search-result-" + result.id + "-" + index}>

                        <td>
                            <p className="table_elements">{(result.type) === "switchboard" ? "Q" : "P"}</p>
                        </td>

                        <td>
                            <p className="table_elements">{result.name}</p>
                        </td>

                        <td>
                            <p>{result.city}</p>
                        </td>
                        <td>
                            <button className="button" style={{
                                minWidth: 0,
                                aspectRatio: 1,
                            }} onClick={() => {

                                //open after a second
                                setTimeout(() => {
                                    //Open the popup on that switchboard
                                    map.eachLayer((layer) => {
                                        if (layer instanceof L.Marker) {
                                            if (layer.getLatLng().lat === result.lat && layer.getLatLng().lng === result.lng) {
                                                layer.openPopup();
                                            }
                                        }
                                    });
                                }, 2000)
                                setResults([])

                                map.flyTo([result.lat, result.lng], 20, {animate: true, duration: 1})

                            }} >
                                <IoArrowForward ></IoArrowForward>
                            </button>
                        </td>
                    </tr>
                ))}
                    </tbody>
                </table>

            </div>



            <MapContainer
                center={[44.83937639856758, 10.007951484788567]}
                zoom={13}
                style={{height: '80vh', width: '100%',}}
                ref={setMap}
            >
                <TileLayer
                    detectRetina={true}
                    maxZoom={22}
                    maxNativeZoom={18}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'

                />
                <MarkerClusterGroup disableClusteringAtZoom={18}>
                    {state.switchBoards.map((switchBoard) => (
                        <Marker key={"switchboard-" + switchBoard.id} position={[switchBoard.lat, switchBoard.lng]}
                                icon={createFlashMapIcon(switchBoard.name)}
                                eventHandlers={{
                                    click: () => {
                                        //setPointAddress("audioservizi...")
                                        //getAddressFromCoordinates(switchBoard.lat, switchBoard.lng)
                                    }
                                }}
                        >
                            <Popup >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    gap: '10px'
                                }}>
                                    <div>
                                        <b>Quadro {switchBoard.name}</b><br/>
                                        {switchBoard.description && switchBoard.description} <br/>
                                        {//
                                        } <br/>
                                        {switchBoard.city}<br/>
                                        {switchBoard.manager}<br/>
                                    </div>
                                    {<button key={"navigate-switchboard-" + switchBoard.id} className="mapButton"
                                         onClick={() => openInGoogleMapsNavigation([switchBoard.lng, switchBoard.lat])}>Naviga</button>}
                                </div>
                            </Popup>
                        </Marker>
                    ))}

                    {state.streetLights.map((streetLight) => (
                        <Marker key={"streetlight-"+streetLight.id} position={[streetLight.lat, streetLight.lng]}
                                icon={createLightMapIcon(streetLight.name)}
                                eventHandlers={{
                                    click: () => {
                                        setPointAddress("audioservizi...")
                                        getAddressFromCoordinates(streetLight.lat, streetLight.lng)
                                    }
                                }}
                        >
                            <Popup>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    gap: '10px'
                                }}>
                                    <div>
                                        <b>Corpo Illuminante {streetLight.name}</b><br/>
                                        {state.switchBoards.map((value) => value.id === streetLight.switchboardId && "Accensione da quadro " + value.name)}<br/>
                                        {//pointAddress
                                        } <br/>
                                        {streetLight.description && streetLight.description} <br/>
                                        {streetLight.city}<br/>
                                        {streetLight.manager} <br/>
                                    </div>
                                    {<button key={"navigate-streetlight-" + streetLight.id} className="mapButton"
                                             onClick={() => openInGoogleMapsNavigation([streetLight.lng, streetLight.lat])}>Naviga</button>}
                                    {state.switchBoards.map((value) => value.id === streetLight.switchboardId &&
                                        <button  key={"center-streetlight-" + streetLight.id} className="mapButton"
                                            onClick={() => centerOnSwitchboard(streetLight)}>
                                            Centra su Quadro
                                        </button>)}
                                </div>
                            </Popup>
                        </Marker>
                    ))
                    }
                </MarkerClusterGroup>
            </MapContainer>
        </div>

    );
};

export default Map;