 import NavBar from "../../components/NavBar";
import SearchBox from "../../components/Search/SearchBox";
import {useEffect, useState} from "react";
import WorksTable from "../../components/Search/WorksTable";
import LoadingError from "../../components/LoadingError";
import LoadingSpinner from "../../components/LoadingSpinner";
import toHtmlInputDate from "../../components/functions/toHtmlInputDate";
import PageSelector from "../../components/Search/PageSelector";

const Search = () => {

    //Const pages values
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [errorType, setErrorType] = useState("");

    // Search values
    const [client, setClient] = useState(localStorage.getItem("searchValues") ? JSON.parse(localStorage.getItem("searchValues")).client : "")
    const [end, setEnd] = useState(localStorage.getItem("searchValues") ? new Date(JSON.parse(localStorage.getItem("searchValues")).end) : new Date())
    const [start, setStart] = useState(localStorage.getItem("searchValues") ? new Date(JSON.parse(localStorage.getItem("searchValues")).start) : new Date(end.getFullYear(), end.getMonth() - 1, 1))
    const [processed, setProcessed] = useState(localStorage.getItem("searchValues") ? JSON.parse(localStorage.getItem("searchValues")).processed : "indifferente")
    const [description, setDescription] = useState(localStorage.getItem("searchValues") ? JSON.parse(localStorage.getItem("searchValues")).description : "")
    const [page, setPage] = useState(localStorage.getItem("searchValues") ? JSON.parse(localStorage.getItem("searchValues")).page : 0)
    const [numberOfPages, setNumberOfPages] = useState(localStorage.getItem("searchValues") ? JSON.parse(localStorage.getItem("searchValues")).numberOfPages : 0)

    const searchValues = {client, start, end, processed, description, page, numberOfPages}
    const setSearchValues = {setClient, setStart, setEnd, setProcessed, setDescription}

    //Works values
    const [reports, setReports] = useState([])

    const loadReports = () => {
        setIsLoading(true)
        let url = process.env.REACT_APP_BACKEND_URL + "/works/search?customer=" + client + "&start=" + toHtmlInputDate(start) + "&end=" + toHtmlInputDate(end) + "&processed=" + processed + "&description=" + description + "&page=" + page

        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((res) => {
                if (res.status === 200) {
                    res.json().then((data) => {
                        setNumberOfPages(Math.floor(data.count / 51));

                        data.works.forEach((element) => {
                            element.isExpandedOnMobile = false;
                        })

                        setReports(data.works);
                        setIsLoading(false);
                    });
                }
            })
            .catch(() => {
                    setErrorType("Network error");
                    setLoadingError(true);
                }
            );
    }

    //Update the new search values
    useEffect(() => {
        localStorage.setItem("searchValues", JSON.stringify(searchValues))
    }, [searchValues])

    //Load the reports
    useEffect(() => {
        loadReports()
    }, [start, end, processed, page])

    return (
        loadingError ? <LoadingError errorDescription={errorType} /> :
            <div className="mainContainer">
                <NavBar/>
                <SearchBox values={searchValues} setValues={setSearchValues} refresh={loadReports}/>
                {isLoading ? <LoadingSpinner/> :
                <WorksTable reports={reports} setReports={setReports}/>}
                <PageSelector page={page} setPage={setPage} numberOfPages={numberOfPages}/>
            </div>
    )
}

export default Search